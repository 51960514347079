import { jsx as _jsx } from "react/jsx-runtime";
import { useReducer, createContext, useContext, useEffect } from 'react';
import useFetchSummaryStatistics from '../hooks/useFetchSummaryStatistics';
import { setIsCurrentUserAction, setProfileDataAction } from './UserProfileActions';
import userProfileReducer from './userProfileReducer';
import getDefaultUserProfileContext from '../helpers/getDefaultUserProfileContext';
export const UserProfileStateContext = createContext(getDefaultUserProfileContext());
export const UserProfileDispatchContext = createContext({
    dispatch: (x) => x,
});
export const UserProfileProvider = ({ children, handle, loggedInUserProfile, preview, }) => {
    const [state, dispatch] = useReducer(userProfileReducer, getDefaultUserProfileContext());
    const { isFetching, data, error } = useFetchSummaryStatistics(handle);
    useEffect(() => {
        if (!isFetching) {
            /**
             * May be able to simplify the state this if it's all in one request anyway
             */
            if (data) {
                dispatch(setProfileDataAction(data));
            }
            // check if it's logged in user
            if ((loggedInUserProfile === null || loggedInUserProfile === void 0 ? void 0 : loggedInUserProfile.handle) && loggedInUserProfile.handle.toLowerCase() === (handle === null || handle === void 0 ? void 0 : handle.toLowerCase())) {
                dispatch(setIsCurrentUserAction(true));
            }
        }
    }, [isFetching, data, loggedInUserProfile === null || loggedInUserProfile === void 0 ? void 0 : loggedInUserProfile.handle]);
    return (_jsx(UserProfileStateContext.Provider, { value: {
            ...state,
            isFetching,
            error,
            isCurrentUser: state.isCurrentUser && !preview,
            preview
        }, children: _jsx(UserProfileDispatchContext.Provider, { value: {
                dispatch,
            }, children: children }) }));
};
export const useUserProfileContextState = () => {
    const context = useContext(UserProfileStateContext);
    if (context === undefined) {
        throw new Error('useUserProfileContextState must be used within a UserProfileProvider');
    }
    return context;
};
export const useUserProfileDispatch = () => {
    const context = useContext(UserProfileDispatchContext);
    if (context === undefined) {
        throw new Error('useUserProfileDispatch must be used within a UserProfileProvider');
    }
    return context;
};
export const useUserProfileContext = () => {
    return {
        ...useUserProfileContextState(),
        ...useUserProfileDispatch(),
    };
};
export default UserProfileProvider;
