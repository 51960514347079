import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import ModalContent from '@components/Modals/components/ModalContent';
import useTrackEvent from '@hooks/useTrackEvent';
export const ContentExposeDota2Profile = ({ className, stepName, onContinue, }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Continue',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onContinue) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, children: [_jsxs("section", { children: [_jsxs("h3", { children: ["Make your ", _jsx("span", { "data-ignore": true, children: "Steam" }), " profile public"] }), _jsxs("p", { children: ["In order for your ", _jsx("span", { "data-ignore": true, children: "Dota 2" }), " games to count automatically, you need to make your", ' ', _jsx("span", { "data-ignore": true, children: "Steam" }), " account public."] }), _jsxs("ol", { className: className, children: [_jsxs("li", { children: ["Open ", _jsx("span", { "data-ignore": true, children: "Steam" }), " or go to", ' ', _jsx("a", { target: "_blank", href: "http://steamcommunity.com", rel: "noreferrer noopenner", children: "steamcommunity.com" })] }), _jsx("li", { children: "Go to your profile page" }), _jsx("li", { children: "Click \"Edit Profile\"" }), _jsx("li", { children: "Click \"My Privacy Settings\"" }), _jsx("li", { children: "Set \"Profile Status\" to \"Public\"" }), _jsx("li", { children: "Save Changes" })] })] }), _jsx(CoreButton, { type: "button", onClick: handleClick, size: "lg", variant: "dota2", children: "Continue" })] }));
};
export default ContentExposeDota2Profile;
