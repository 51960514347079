import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, createContext, useContext } from 'react';
export const PlatformSelectAnimationContext = createContext({
    shouldAnimate: false,
    endAnimationIconPosition: undefined,
    setEndAnimationIconPosition: () => { },
    startAnimation: () => { },
    stopAnimation: () => { },
});
const PlatformSelectAnimationProvider = ({ children }) => {
    const [endAnimationIconPosition, setEndAnimationIconPosition] = useState();
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const contextValue = useMemo(() => {
        const startAnimation = () => {
            setShouldAnimate(true);
        };
        const stopAnimation = () => {
            setShouldAnimate(false);
        };
        return {
            shouldAnimate,
            endAnimationIconPosition,
            setEndAnimationIconPosition,
            startAnimation,
            stopAnimation,
        };
    }, [shouldAnimate, setShouldAnimate, endAnimationIconPosition, setEndAnimationIconPosition]);
    return (_jsx(PlatformSelectAnimationContext.Provider, { value: contextValue, children: children }));
};
export const usePlatformSelectAnimation = () => {
    const context = useContext(PlatformSelectAnimationContext);
    return context;
};
export default PlatformSelectAnimationProvider;
