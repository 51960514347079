import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/button-has-type */
import * as React from 'react';
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CoreButton from './CoreButton';
// Helper functions that we can use after we migrate CoreButton to styled-components
// type StyleValues = ButtonProps & { theme: Theme };
//
// const getPalette = ({ variant = 'primary', theme }: StyleValues) =>
//   variant === 'grey'
//     ? { light: theme.palette.grey[200], main: theme.palette.grey[400], dark: theme.palette.grey[800] }
//     : theme.palette[variant];
// const getFontSize = ({ size }: StyleValues) => (size === 'lg' ? 14 : 12);
// const getHeight = ({ size }: StyleValues) => (size === 'lg' ? 50 : size === 'md' ? 45 : 40);
// const getBackgroundColour = (values: StyleValues) => {
//   const palette = getPalette(values);
//   return values.appearance === 'fill'
//     ? palette.main
//     : values.appearance === 'subdued'
//     ? palette.light
//     : values.theme.palette.common.white;
// };
// const getHoverColour = (values: StyleValues) => {
//   const palette = getPalette(values);
//   return values.appearance === 'fill' ? palette.dark : palette.main;
// };
// const getBorderColour = (values: StyleValues) => {
//   const palette = getPalette(values);
//   return values.appearance === 'outline' ? palette.main : getBackgroundColour(values);
// };
// const getTextColour = (values: StyleValues) => {
//   const palette = getPalette(values);
//   return values.appearance === 'fill'
//     ? values.theme.palette.common.white
//     : values.variant === 'grey'
//     ? palette.dark
//     : palette.main;
// };
const useStyles = makeStyles()((theme, { fontSize, height, backgroundColour, hoverColour, borderColour, textColour }) => ({
    button: {
        fontSize: fontSize,
        height: height,
        color: textColour,
        backgroundColor: backgroundColour,
        border: `1px solid ${borderColour}`,
        '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: hoverColour,
            border: '1px solid transparent',
            '& > svg': {
                fill: theme.palette.common.white,
            },
        },
        '&:focus': {
            color: textColour,
            border: `1px dotted ${textColour}`,
            '&:hover': {
                color: theme.palette.common.white,
                border: '1px dotted white',
            },
        },
        '& > svg': {
            fill: textColour,
            width: '20px',
            margin: theme.spacing(1),
        },
    },
}));
/**
 * A box shaped button that is heavily restricted to comply with our style guide.
 * This component aims to consolidate common styles applied to our buttons.
 * It uses CoreButton under the hood, so still needs to use makeStyles for now.
 */
const BoxButton = React.forwardRef(({ children, to = undefined, id = undefined, type = 'button', disabled = false, loading, ariaLabel = undefined, testId = undefined, onClick = undefined, appearance = 'fill', size = 'md', variant = 'primary', className = '', target = '_blank', }, ref) => {
    const theme = useTheme();
    const palette = variant === 'grey'
        ? { light: theme.palette.grey[200], main: theme.palette.grey[400], dark: theme.palette.grey[800] }
        : theme.palette[variant];
    const backgroundColour = appearance === 'fill' ? palette.main : appearance === 'subdued' ? palette.light : theme.palette.common.white;
    const hoverColour = appearance === 'fill' ? palette.dark : palette.main;
    const borderColour = appearance === 'outline' ? palette.main : backgroundColour;
    const textColour = appearance === 'fill' ? theme.palette.common.white : variant === 'grey' ? palette.dark : palette.main;
    const fontSize = size === 'lg' ? 14 : 12;
    const height = size === 'lg' ? 50 : size === 'md' ? 45 : 40;
    const { classes, cx } = useStyles({
        fontSize,
        height,
        backgroundColour,
        hoverColour,
        borderColour,
        textColour,
    });
    return (_jsx(CoreButton, { to: to, id: id, type: type, disabled: disabled, loading: loading, ariaLabel: ariaLabel, testId: testId, onClick: onClick, className: cx(className, classes.button), ref: ref, target: target, variant: variant, children: children }));
});
// Styled-components that we can use after we migrate CoreButton to styled-components
// (
//   (values) => `
//   font-size: ${getFontSize(values)};
//     height: ${getHeight(values)};
//     color: ${getTextColour(values)};
//     background-color: ${getBackgroundColour(values)};
//     border: 1px solid ${getBorderColour(values)};
//     &:hover {
//       color: ${values.theme.palette.common.white};
//       background-color: ${getHoverColour(values)};
//       border: 1px solid ${getHoverColour(values)};
//       & > svg {
//         fill: ${values.theme.palette.common.white};
//       },
//     },
//     & > svg: {
//       fill: ${getTextColour(values)};
//       width: 20px;
//       margin: ${values.theme.spacing(1)};
//     }
// `
// );
export default BoxButton;
