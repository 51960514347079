import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ModalContent from "@components/Modals/components/ModalContent";
import CoreButton from "@components/Buttons/CoreButton";
import useTrackEvent from '@hooks/useTrackEvent';
export const ContentUpdateSteamSettings = ({ className, stepName, onContinue, }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Continue',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onContinue) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, children: [_jsxs("section", { children: [_jsxs("p", { children: ["The next step is to make your ", _jsx("span", { "data-ignore": true, children: "Dota 2" }), " match data public. We will not be able to see any other information on your account."] }), _jsxs("ol", { className: className, children: [_jsxs("li", { children: ["Start ", _jsx("span", { "data-ignore": true, children: "Dota 2" })] }), _jsx("li", { children: "Open Settings" }), _jsx("li", { children: "Switch to Tab \"Options\" and select \"To Advanced Options\"" }), _jsx("li", { children: "Switch to tab \"Social\" and check box \"Expose Public Match Data\" " }), _jsx("li", { children: "Switch to your profile and click \"Edit Profile\"" }), _jsx("li", { children: "Save Changes" })] }), _jsx("p", { children: "We will not share your data with any other company or third party." })] }), _jsx(CoreButton, { type: "button", onClick: handleClick, size: "lg", variant: "dota2", children: "Continue" })] }));
};
export default ContentUpdateSteamSettings;
