import { jsx as _jsx } from "react/jsx-runtime";
import BrawlStarsWordmark from './WordMarks/BrawlStarsWordmark';
import PubgWordmark from './WordMarks/PubgWordmark';
import CodWarzone2Wordmark from './WordMarks/CodWarzone2Wordmark';
import CodWarzoneWordmark from './WordMarks/CodWarzoneWordmark';
import Dota2Wordmark from './WordMarks/Dota2Wordmark';
import FortniteWordmark from './WordMarks/FortniteWordmark';
import LeagueOfLegendsWordmark from './WordMarks/LeagueOfLegendsWordmark';
import Destiny2Wordmark from './WordMarks/Destiny2Wordmark';
import RocketLeagueWordmark from './WordMarks/RocketLeagueWordmark';
import ValorantWordmark from './WordMarks/ValorantWordmark';
import ApexLegendsWordmark from './WordMarks/ApexLegendsWordmark';
import OverwatchWordmark from './WordMarks/OverwatchWordmark';
import Rainbow6Wordmark from './WordMarks/Rainbow6Wordmark';
import CSGOWordMark from './WordMarks/CSGOWordmark';
import MLBTheShow23Wordmark from './WordMarks/MLBTheShow23Wordmark';
import PubgMobileWordmark from './WordMarks/PubgMobileWordmark';
import Madden25Wordmark from './WordMarks/Madden25Wordmark';
const GameWordmark = ({ gameKey, height, width, className }) => {
    switch (gameKey) {
        case 'league_of_legends':
            return _jsx(LeagueOfLegendsWordmark, { height: height, width: width, className: className });
        case 'pc-dota_2':
            return _jsx(Dota2Wordmark, { height: height, width: width, className: className });
        case 'fortnite':
            return _jsx(FortniteWordmark, { height: height, width: width, className: className });
        case 'cod-warzone':
            return _jsx(CodWarzoneWordmark, { height: height, width: width, className: className });
        case 'cod-warzone-2':
            return _jsx(CodWarzone2Wordmark, { height: height, width: width, className: className });
        case 'pubg':
            return _jsx(PubgWordmark, { height: height, width: width, className: className });
        case 'brawl-stars':
            return _jsx(BrawlStarsWordmark, { height: height, width: width, className: className });
        case 'rocket-league':
            return _jsx(RocketLeagueWordmark, { height: height, width: width, className: className });
        case 'mlb-the-show-23':
            return _jsx(MLBTheShow23Wordmark, { height: height, width: width, className: className });
        case 'destiny-2':
            return _jsx(Destiny2Wordmark, { height: height, width: width, className: className });
        case 'valorant':
            return _jsx(ValorantWordmark, { height: height, width: width, className: className });
        case 'apex-legends':
            return _jsx(ApexLegendsWordmark, { height: height, width: width, className: className });
        case 'rainbow6':
            return _jsx(Rainbow6Wordmark, { height: height, width: width, className: className });
        case 'overwatch':
            return _jsx(OverwatchWordmark, { height: height, width: width, className: className });
        case 'csgo':
            return _jsx(CSGOWordMark, { height: height, width: width, className: className });
        case 'pubg-mobile':
            return _jsx(PubgMobileWordmark, { height: height, width: width, className: className });
        case 'madden-25':
            return _jsx(Madden25Wordmark, { height: height, width: width, className: className });
    }
};
export default GameWordmark;
