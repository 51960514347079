import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import Error404 from '@domain/Errors/components/Error404';
import LoadingPanel from '@domain/Tournament/components/LoadingPanel';
import useGamesList from '@hooks/API/useGamesList';
import { useFeature } from 'flagged';
import CompetePage from './components/CompetePage';
const CompeteScreen = () => {
    const { allPlatforms, isLoading } = useGamesList();
    const { platformSlug } = useParams();
    const isPlatformFirstEnabled = Boolean(useFeature('enable_platform_first'));
    const isValidPlatform = platformSlug && !!allPlatforms.find((platform) => platform.slug === platformSlug);
    if (isLoading) {
        return _jsx(LoadingPanel, {});
    }
    if (isPlatformFirstEnabled && (!platformSlug || isValidPlatform)) {
        return _jsx(CompetePage, {});
    }
    return _jsx(Error404, {});
};
export default CompeteScreen;
