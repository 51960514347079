import ErrorWithCause from '@helpers/ErrorWithCause';
import { isAxiosError } from '@helpers/errorHelper';
import { captureMessage, Severity } from '@sentry/react';
import axios from 'axios';
const getCurrentPaypalState = (error) => {
    var _a;
    if (!isAxiosError(error)) {
        return undefined;
    }
    return (_a = error.response) === null || _a === void 0 ? void 0 : _a.data;
};
const completePaypalDeposit = async (request) => {
    var _a;
    const requestPath = '/api/deposit/paypal/v1/';
    try {
        const { paypalOrderId, ...requestBody } = request;
        const response = await axios.patch(`${requestPath}${paypalOrderId}`, requestBody);
        return response.data;
    }
    catch (error) {
        const { data: errorData } = (_a = getCurrentPaypalState(error)) !== null && _a !== void 0 ? _a : {};
        let level = Severity.Critical;
        if (request.newOrderState === 'cancelled' && (errorData === null || errorData === void 0 ? void 0 : errorData.currentState) === 'cancelled') {
            level = Severity.Info;
        }
        captureMessage('Unable to complete the paypal deposit', {
            level,
            extra: {
                requestMethod: 'PATCH',
                requestPath,
                paypalOrderId: request.paypalOrderId,
                newOrderState: request.newOrderState,
                currentOrderState: errorData === null || errorData === void 0 ? void 0 : errorData.currentState,
            },
        });
        throw new ErrorWithCause(error);
    }
};
export default completePaypalDeposit;
