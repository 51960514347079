import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useRemoveWrapperPadding from '@hooks/useRemoveWrapperPadding';
import useSetPageHeaderStyle from '@hooks/useSetPageHeaderStyle';
import Banner from './components/Banner';
import Results from './components/Results';
import Expertise from './components/Expertise';
import StartCampaign from './components/StartCampaign';
import Capabilities from './components/Capabilities';
import Contact from './components/Contact';
import useStyles from './useStyles';
const BrandLandingPage = () => {
    const { classes } = useStyles();
    useRemoveWrapperPadding();
    // No header currently on this page
    useSetPageHeaderStyle(0);
    return (_jsxs("div", { className: classes.pageWrapper, children: [_jsx(Banner, {}), _jsx(Results, {}), _jsx(Expertise, {}), _jsx(StartCampaign, {}), _jsx(Capabilities, {}), _jsx(Contact, {})] }));
};
export default BrandLandingPage;
