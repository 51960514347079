import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import 'firebase/compat/auth';
import { captureException } from '@sentry/react';
import { useUpdateUserState, useUserState } from '@context/UserContext';
import { XMarkIcon } from '@heroicons/react/24/solid';
import GenericModal from '@components/Modals/GenericModal';
import LoadingModal from '@components/Modals/LoadingModal/LoadingModal';
import TextInput from '@components/Fields/TextInput';
import validateLogin from '@components/Modals/AuthenticationModal/Components/Login/helpers/validateLogin';
import AuthenticationSupport from '@components/Modals/AuthenticationModal/Components/Login/components/AuthenticationSupport';
import useFirebaseAuthRequest from '@components/Modals/AuthenticationModal/Hooks/useFirebaseAuthRequest';
import useGetRecaptureEnabled from '@components/Modals/AuthenticationModal/Hooks/useGetRecaptureEnabled';
import useCurrentLoginProvider from '@components/Modals/AuthenticationModal/Hooks/useCurrentLoginProvider';
import BoxButton from '@components/Buttons/BoxButton';
import useSignOut from '@components/Hooks/useSignOut';
import isRecentlyAuthenticated from '@helpers/isRecentlyAuthenticated';
import ErrorWithCause from '@helpers/ErrorWithCause';
const useStyles = makeStyles()((theme) => ({
    container: { padding: '20px', display: 'flex', flexDirection: 'column', maxWidth: '430px', fontFamily: 'Montserrat' },
    closeBtn: {
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        display: 'inline-flex',
        height: '30px',
        justifyContent: 'center',
        lineHeight: '14px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '30px',
        '& .fal::before': {
            fontSize: '20px',
        },
        color: '#a1a1a1',
    },
    subheading: {
        margin: '30px 60px 10px',
        fontSize: '11px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        '& > label': {
            marginTop: theme.spacing(1),
            fontSize: '12px',
            color: theme.palette.grey[900],
        },
    },
    submitBtn: { alignSelf: 'stretch', marginTop: theme.spacing(1) },
    loginFormError: {
        color: theme.palette.error.main,
        fontSize: '11px',
        textAlign: 'center',
        fontWeight: 600,
    },
}));
const ControlledReauthenticateModal = ({ show, handleClose, handleSuccess }) => {
    const { classes, cx } = useStyles();
    const { userDetails } = useUserState();
    const { refresh: refreshUserState } = useUpdateUserState();
    const { isLoading: isLoadingCurrentLoginProvider, currentLoginProvider } = useCurrentLoginProvider();
    const [passwordField, setPasswordField] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const { signOut } = useSignOut();
    const lastLogin = userDetails === null || userDetails === void 0 ? void 0 : userDetails.lastLogin;
    // only request recaptcha if there is a valid lastLogin
    const enableRecapture = Boolean(lastLogin && !isRecentlyAuthenticated(new Date(lastLogin)) && currentLoginProvider === 'Email');
    const { data, isLoading: isLoadingRecapture } = useGetRecaptureEnabled(enableRecapture);
    const handleFirebaseAuthSuccess = () => {
        // Invalidate user cache to get new user last login time
        refreshUserState();
        if (handleSuccess) {
            handleSuccess();
        }
    };
    const handleFirebaseAuthFail = async () => {
        await signOut();
        // Hitting the logout endpoint to ensure PHP session ends
        const serverLogoutUrl = '/logout';
        try {
            await axios.get(serverLogoutUrl);
        }
        catch (error) {
            const e = new ErrorWithCause(error);
            captureException(e, { extra: { request: 'logout' } });
        }
        window.location.href = '/user/login';
    };
    const [{ loading: isLoadingFirebaseAuth }, requestFirebaseAuth] = useFirebaseAuthRequest({
        onSuccess: handleFirebaseAuthSuccess,
        onFail: handleFirebaseAuthFail,
    });
    const handleSubmitEvent = (event) => {
        event.preventDefault();
        if ((userDetails === null || userDetails === void 0 ? void 0 : userDetails.email) && passwordField) {
            const submissionFormData = {
                email: userDetails.email,
                password: passwordField,
            };
            requestFirebaseAuth(submissionFormData);
        }
    };
    const handleBlur = (e) => {
        var _a;
        const fieldName = e.currentTarget.name;
        const loginValidation = validateLogin({
            email: (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.email) !== null && _a !== void 0 ? _a : '',
            password: passwordField,
        });
        loginValidation.passes(); // triggers the error msgs to become available
        const validationError = loginValidation.errors.first(fieldName) || ''; // set or reset error.
        setValidationErrors({ [fieldName]: validationError });
    };
    if (isLoadingRecapture || isLoadingCurrentLoginProvider) {
        return _jsx(LoadingModal, { "data-testid": "loadingModal", show: true, loadingText: "Loading..." });
    }
    return (_jsx(GenericModal, { show: show, size: "medium", onClose: handleClose, children: _jsxs("div", { className: classes.container, children: [_jsx("button", { type: "button", onClick: handleClose, className: cx('btn-close', classes.closeBtn), "aria-label": "close reauthenticate modal", children: _jsx(XMarkIcon, { viewBox: "0 0 24 24" }) }), _jsx("p", { className: classes.subheading, children: "Your password is required to perform this action" }), _jsxs("form", { onSubmit: handleSubmitEvent, className: classes.form, children: [_jsx(TextInput, { id: "confirm-password", type: "password", onChange: (event) => setPasswordField(event.currentTarget.value), onBlur: handleBlur, value: passwordField, label: "Your Password", error: !!validationErrors.password, errorMessage: validationErrors.password }), _jsx(AuthenticationSupport, { closeAction: handleClose }), (data === null || data === void 0 ? void 0 : data.recaptchaEnabled) && (_jsx("div", { className: "g-recaptcha", "data-sitekey": "6Ldk1cIUAAAAACQiYDy5zcbF33p3e4Iahsmz0FE9" })), _jsx(BoxButton, { className: classes.submitBtn, type: "submit", loading: isLoadingFirebaseAuth, disabled: isLoadingFirebaseAuth, size: "md", ariaLabel: 'login', children: "Continue" })] })] }) }));
};
export default ControlledReauthenticateModal;
