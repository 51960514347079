import axios from 'axios';
import { useEffect, useState } from 'react';
import configureAxiosFirebaseInterceptor from '@helpers/configureAxiosFirebaseInterceptor';
/**
 * This hook will add an interceptor to the default
 * Axios instance to get firebase's user id token and sets it
 * in the Authorization header before sending a request.
 *
 * Internally checks whether firebase auth is enabled before appending the interceptor.
 *
 */
const useAxiosFirebaseInterceptor = () => {
    const [isReady, setIsReady] = useState(false);
    useEffect(() => {
        const axiosInterceptorId = configureAxiosFirebaseInterceptor();
        setIsReady(true);
        return () => axios.interceptors.request.eject(axiosInterceptorId);
    }, []);
    return { isReady };
};
export default useAxiosFirebaseInterceptor;
