import fetchNotifications from '@api/fetchNotifications';
import { useUserState } from '@context/UserContext';
import { captureException } from '@sentry/react';
import { useInfiniteQuery } from 'react-query';
import useUpdateNewNotificationsCount from './useUpdateNewNotificationsCount';
export const infiniteNotificationKey = 'infiniteNotifications';
const useFetchInfiniteNotifications = (enabled = true) => {
    var _a;
    const { authenticated } = useUserState();
    const updateNewNotificationsCount = useUpdateNewNotificationsCount();
    const NOTIFICATIONS_PER_PAGE = 15;
    const query = useInfiniteQuery([infiniteNotificationKey], async ({ pageParam }) => {
        return fetchNotifications({
            limit: NOTIFICATIONS_PER_PAGE,
            offset: pageParam !== null && pageParam !== void 0 ? pageParam : 0,
        });
    }, {
        // hasNextPage = true if getNextPageParam !== undefined
        getNextPageParam: (lastPage, pages) => {
            const maxNotificationPages = 4;
            const totalNotifications = lastPage.meta.pagination.total;
            // If there are less than 15 notifications, only return the first page
            if (!totalNotifications || totalNotifications < NOTIFICATIONS_PER_PAGE) {
                return undefined;
            }
            // If the user keep scrolling through notifications, stop at 4 pages
            if (pages.length >= maxNotificationPages) {
                return undefined;
            }
            // If the loaded notifications > total notifications, stop
            const totalPages = Math.ceil(totalNotifications / NOTIFICATIONS_PER_PAGE);
            const currentNotificationTotal = pages.length * NOTIFICATIONS_PER_PAGE;
            if (totalPages && totalNotifications && currentNotificationTotal > totalNotifications) {
                return undefined;
            }
            // return the next 15 results
            return pages.length * NOTIFICATIONS_PER_PAGE;
        },
        enabled: authenticated && enabled,
        refetchInterval: 1000 * 60 * 10,
        onSuccess: (response) => {
            var _a, _b, _c;
            updateNewNotificationsCount((_c = (_b = (_a = response.pages[response.pages.length - 1]) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.notification.totalNew) !== null && _c !== void 0 ? _c : 0);
        },
    });
    if (query.error) {
        captureException(query.error, {
            extra: {
                queryKey: infiniteNotificationKey,
                limit: NOTIFICATIONS_PER_PAGE,
                offset: (_a = query.data) === null || _a === void 0 ? void 0 : _a.pageParams,
                request: 'fetchInfiniteNotifications',
            },
        });
    }
    return query;
};
export default useFetchInfiniteNotifications;
