const getSocialMediaServiceNameFromType = (type) => {
    switch (type) {
        case 'twitch':
            return 'Twitch';
        case 'twitter':
            return 'Twitter';
        case 'youtube':
            return 'YouTube';
        case 'instagram':
            return 'Instagram';
        case 'discord':
            return 'Discord';
        case 'facebook':
            return 'Facebook';
        case 'reddit':
            return 'Reddit';
        case 'google':
            return 'Google';
        case 'psn':
            return 'PlayStation Network';
        case 'apple':
            return 'Apple';
    }
};
export default getSocialMediaServiceNameFromType;
