import axios from 'axios';
import { getAuth } from 'firebase/auth';
/**
 * Configure the default Axios instance to query for firebase ID token,
 * before making a request.
 *
 * This should only be called inside the FirebaseAuthGuard, where firebase
 * is ready (otherwise the user will be null).
 *
 * @returns AxiosRequestConfig
 */
const configureAxiosFirebaseInterceptor = () => {
    return axios.interceptors.request.use(async (config) => {
        var _a;
        const auth = getAuth();
        await auth.authStateReady();
        const idToken = await ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken());
        const { headers } = config;
        if (idToken) {
            headers.Authorization = `Bearer ${idToken}`;
        }
        if (idToken) {
            return {
                ...config,
                headers,
            };
        }
        return config;
    });
};
export default configureAxiosFirebaseInterceptor;
