import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import PageFooter from "@components/UI/PageFooter";
import BodyContent from '@domain/BrandedTournament/components/BodyContent';
import useGamesList from '@hooks/API/useGamesList';
import useRemoveWrapperPadding from '@hooks/useRemoveWrapperPadding';
import { makeStyles } from 'tss-react/mui';
import { Grid, useMediaQuery } from '@mui/material';
import { useLocalAppContext } from '@context/LocalAppContext';
import GameTile from '@components/Buttons/GameTile';
import { useTheme, styled } from '@mui/material/styles';
import useTrackEvent from '@hooks/useTrackEvent';
import LandingPageBanner from './components/LandingPageBanner';
import UnlimitedTournaments from './components/UnlimitedTournaments';
import UnlimitedLogoSvg from './components/UnlimitedLogoSvg';
import ComingSoonTile from './components/ComingSoonTile';
const sectionOffsetTop = 160;
const backgroundColor = '#1f2123';
const useStyles = makeStyles()((theme) => ({
    landing: {
        backgroundColor,
    },
    /**
     * pageSection class will divide the page to 3 columns,
     * with two fillers on either side and content in the centre.
     * This allows you to add colours to the "filler" section.
     * main content must have a grid-area: 'content' css prop
     */
    pageSection: {
        display: 'grid',
        gridTemplateAreas: '"fillerLeft content fillerRight"',
        gridTemplateColumns: 'minmax(0,1fr) minmax(auto, 1420px) minmax(0,1fr)',
        '&:before': {
            content: '""',
            gridArea: 'fillerLeft',
        },
        '&:after': {
            content: '""',
            gridArea: 'fillerRight',
        },
    },
    // use in tandem with pageSection
    availableGamesSection: {
        gridTemplateAreas: '". content fillerRight" "fillerLeft content fillerRight"',
        gridTemplateRows: `${sectionOffsetTop}px minmax(160px, 1fr)`,
        position: 'relative',
        marginTop: `-${sectionOffsetTop}px`,
        '&:before': {
            backgroundColor: theme.palette.background.paper,
        },
        '&:after': {
            backgroundImage: `linear-gradient(0deg, ${backgroundColor} 50px, ${theme.palette.background.paper} 50px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
    },
    availableGamesSectionContent: {
        background: theme.palette.background.paper,
        padding: theme.spacing(5, 7),
        gridArea: 'content',
        position: 'relative',
        '&:before': {
            content: '""',
            height: '74px',
            backgroundColor: theme.palette.primary.main,
            width: '20px',
            position: 'absolute',
            top: `${sectionOffsetTop - 74}px`,
            left: '-20px',
            transition: `transform 1s ${theme.transitions.easing.easeInOut}, opacity 1s`,
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(5, 2),
        },
    },
    availableGamesSectionContentDecorationHidden: {
        '&:before': {
            transform: `translateY(-500px)`,
            opacity: 0,
        },
    },
    unlimitedTournamentSection: {
        position: 'relative',
        backgroundColor,
        gridTemplateColumns: `minmax(${theme.spacing(2)},1fr) minmax(auto, 1420px) minmax(${theme.spacing(2)},1fr)`,
        '&:after': {
            background: `url(/img/landing/unlimited_tournaments_bg_decoration.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
        [theme.breakpoints.down('md')]: {
            '&:after': {
                background: 'transparent',
            },
        },
    },
    unlimitedTournamentDecorText: {
        position: 'absolute',
        maxWidth: '1065px',
        left: 0,
        right: 0,
        top: 0,
        margin: theme.spacing(16, 'auto'),
        animationName: '$unlimitedText',
        animationDuration: '8s',
        animationIterationCount: 'infinite',
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(7, 'auto'),
        },
    },
    unlimitedTournamentContent: {
        gridArea: 'content',
        position: 'relative',
        marginTop: '205px',
        [theme.breakpoints.down('md')]: {
            marginTop: '80px',
        },
    },
    '@keyframes unlimitedText': {
        '0%': {
            stroke: '#43474A',
        },
        '20%': {
            stroke: theme.palette.fortnite.main,
        },
        '40%': {
            stroke: theme.palette.dota2.main,
            filter: `drop-shadow(1px 1px 6px rgba(255,255,255,0.1))`,
        },
        '60%': {
            stroke: theme.palette.primary.main,
        },
        '80%': {
            stroke: 'white',
            filter: `drop-shadow(1px 1px 6px rgba(255,255,255,0.3))`,
        },
        '100%': {
            stroke: '#43474A',
        },
    },
    // Automatic tracking result section
    // Overriding button here, as section has not available props for
    // individual button styles
    marketingContent: {
        position: 'relative',
        '& .automated-result-tracking button': {
            background: 'white',
            color: theme.palette.primary.main,
        },
        '& .automated-result-tracking button:hover': {
            color: 'white',
        },
        '& .automated-result-tracking button:before': {
            background: theme.palette.orange.main,
        },
    },
    bodyText: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
        color: theme.palette.grey[500],
        fontWeight: 600,
        lineHeight: 1.45,
    },
}));
const Heading = styled('h2') `
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
const pageStyle = {
    bodyBackgroundColor: `#00ACF6`,
    accentColor: (colorType) => {
        return colorType === 'solid'
            ? `linear-gradient(0deg, #F77702, #F77702)`
            : 'radial-gradient(41.81% 274.56% at 26.55% 58.19%, #FF9737 0%, #F67600 100%)';
    },
    footerCalloutImage: `/img/landing/home_page_callout.png`,
};
const SiteLandingPage = () => {
    const { classes, cx } = useStyles();
    const queryParams = new URLSearchParams(window.location.search);
    const shouldPromptSignIn = queryParams.has('signIn');
    // used to start animation on page load
    const [animate, setAnimate] = useState(false);
    const { allGames } = useGamesList();
    const { openModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    useRemoveWrapperPadding();
    // the tracking position should be >= 1. 0 belongs to the Header.tsx button.
    const handleRegisterClick = (position, label) => {
        openModal({ type: 'register' });
        trackEvent({
            type: 'initiate_account_registration',
            data: {
                eventName: 'account registration',
                moduleName: 'Button',
                label,
                position,
            },
        });
    };
    // Page Load animations
    useEffect(() => {
        const beginAnimation = setTimeout(() => {
            setAnimate(true);
        }, 500);
        return () => clearTimeout(beginAnimation);
    }, []);
    useEffect(() => {
        if (shouldPromptSignIn) {
            openModal({ type: 'login' });
        }
    }, [shouldPromptSignIn]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (_jsxs("div", { className: cx(classes.bodyText, classes.landing), children: [_jsx(LandingPageBanner, { handleJoinClick: () => handleRegisterClick(1, 'Create Account') }), _jsx("section", { className: cx(classes.pageSection, classes.availableGamesSection), children: _jsxs("div", { className: cx(classes.availableGamesSectionContent, {
                        [classes.availableGamesSectionContentDecorationHidden]: !animate,
                    }), children: [_jsx(Heading, { children: "Available Games" }), _jsxs(Grid, { container: true, spacing: 2, children: [allGames.map((game) => (_jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsx(GameTile, { game: game, size: isMobile ? 'sm' : 'md' }) }, game.id))), _jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsx(ComingSoonTile, { size: isMobile ? 'sm' : 'md' }) }), _jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsx(ComingSoonTile, { size: isMobile ? 'sm' : 'md' }) })] })] }) }), _jsxs("section", { className: cx(classes.unlimitedTournamentSection, classes.pageSection), children: [_jsx(UnlimitedLogoSvg, { className: classes.unlimitedTournamentDecorText }), _jsx(UnlimitedTournaments, { className: classes.unlimitedTournamentContent, handleJoinClick: () => handleRegisterClick(2, 'Join Now') })] }), _jsx(BodyContent, { background: pageStyle.bodyBackgroundColor, accentColor: pageStyle.accentColor, footerCalloutImage: pageStyle.footerCalloutImage, handleJoinClick: handleRegisterClick, className: classes.marketingContent }), _jsx(PageFooter, {})] }));
};
export default SiteLandingPage;
