import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserGroupIcon } from '@heroicons/react/20/solid';
const Column = styled('div') `
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const RankContainer = styled('div') `
  display: flex;
  align-items: center;
`;
const RankIconImg = styled('img') `
  width: 28px;
  height: 28px;
  object-fit: contain;
`;
const RankText = styled('span') `
  color: ${(props) => props.theme.palette.grey[900]};
  font-size: 14px;
  font-weight: 600;
  padding: 0px 4px;
`;
const Divider = styled('div') `
  width: 11px;
  height: 2px;
  background-color: ${(props) => props.theme.palette.grey[900]};
  margin: 0px 4px;
`;
const AllRanksText = styled('span') `
  margin-left: 8px;
`;
const StyledTeamIcon = styled(UserGroupIcon) `
  color: ${(props) => props.theme.palette.primary.main};
`;
const RankTooltipText = (rankType, displayValue) => {
    switch (rankType) {
        case 'minimum':
            return `Minimum rank required: ${displayValue}`;
        case 'maximum':
            return `Maximum rank required: ${displayValue}`;
        case 'specific':
            return `Rank required: ${displayValue} only`;
    }
};
const RankIcon = ({ iconUrl }) => {
    if (iconUrl) {
        return _jsx(RankIconImg, { src: iconUrl });
    }
    return _jsx(UserGroupIcon, { width: 20 });
};
const RankRange = ({ lowestRank, highestRank, className, iconOnly = false }) => {
    if (lowestRank && highestRank) {
        if (lowestRank.id === highestRank.id) {
            return (_jsx(Column, { className: className, children: _jsx(Tooltip, { placement: "top", arrow: true, title: RankTooltipText('specific', lowestRank.displayName), children: _jsxs(RankContainer, { children: [_jsx(RankIcon, { iconUrl: highestRank.iconUrl }), ' ', !iconOnly && lowestRank.rankDivision && _jsx(RankText, { children: lowestRank.rankDivision })] }) }) }));
        }
        const lowestRankDisplayName = lowestRank.rankDivision;
        const highestRankDisplayName = highestRank.rankDivision;
        const tooltipContent = `${RankTooltipText('minimum', lowestRank.displayName)} - ${RankTooltipText('maximum', highestRank.displayName)}`;
        return (_jsx(Column, { className: className, children: _jsx(Tooltip, { placement: "top", arrow: true, title: tooltipContent, children: _jsxs(RankContainer, { children: [_jsx(RankIcon, { iconUrl: lowestRank.iconUrl }), ' ', !iconOnly && lowestRankDisplayName && _jsx(RankText, { children: lowestRankDisplayName }), " ", _jsx(Divider, {}), _jsx(RankIcon, { iconUrl: highestRank.iconUrl }), ' ', !iconOnly && highestRankDisplayName && _jsx(RankText, { children: highestRankDisplayName })] }) }) }));
    }
    if (lowestRank || highestRank) {
        const isLowest = lowestRank != null;
        const rank = lowestRank !== null && lowestRank !== void 0 ? lowestRank : highestRank;
        if (!rank) {
            return _jsx(Column, { className: className });
        }
        const rankDisplayName = rank.rankDivision;
        const tooltipContent = RankTooltipText(isLowest ? 'minimum' : 'maximum', rank.displayName);
        return (_jsx(Column, { className: className, children: _jsx(Tooltip, { placement: "top", arrow: true, title: tooltipContent, children: _jsxs(RankContainer, { children: [_jsx(RankIcon, { iconUrl: rank.iconUrl }), ' ', !iconOnly && (rankDisplayName || isLowest) && (_jsxs(RankText, { children: [rankDisplayName, " ", isLowest && `+`] }))] }) }) }));
    }
    return (_jsx(Column, { className: className, children: _jsx(Tooltip, { placement: "top", arrow: true, title: "All ranks allowed", children: _jsxs(RankContainer, { children: [_jsx(StyledTeamIcon, { width: 20 }), _jsx(AllRanksText, { children: "All Ranks" })] }) }) }));
};
export default RankRange;
