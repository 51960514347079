import { jsx as _jsx } from "react/jsx-runtime";
import DOMPurify, { sanitize } from 'dompurify';
import { useMemo } from 'react';
const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';
DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    var _a;
    if (node.tagName === 'A') {
        if (!node.hasAttribute('target')) {
            node.setAttribute('target', '_self');
        }
        if (node.hasAttribute('target')) {
            node.setAttribute(TEMPORARY_ATTRIBUTE, (_a = node.getAttribute('target')) !== null && _a !== void 0 ? _a : '');
        }
    }
});
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    var _a;
    if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
        node.setAttribute('target', (_a = node.getAttribute(TEMPORARY_ATTRIBUTE)) !== null && _a !== void 0 ? _a : '');
        node.removeAttribute(TEMPORARY_ATTRIBUTE);
        if (node.getAttribute('target') === '_blank') {
            node.setAttribute('rel', 'noopener noreferrer');
        }
    }
});
const useParseHtml = (html) => {
    return useMemo(() => {
        if (html) {
            const clean = sanitize(html, { USE_PROFILES: { html: true } });
            return _jsx("div", { dangerouslySetInnerHTML: { __html: clean } });
        }
        return null;
    }, [html]);
};
export default useParseHtml;
