import { jsx as _jsx } from "react/jsx-runtime";
import { captureException } from '@sentry/react';
import getGamePalette from '@helpers/getGamePalette';
import postSaveGameDetails from '@helpers/postSaveGameDetails';
import { setIsLoadingAction, setGameConnectStatusAction, setFetchedSuccessResponse, setFetchedErrorResponse, } from './context/AddGameStepsActions';
import { useAddGameContext } from './context/AddGameProvider';
import isRegion from './helpers/isRegion';
import SaveGameClientError from '../../helpers/SaveGameClientError';
import CoreButton from '@components/Buttons/CoreButton';
const SaveGameDetails = ({ lookupValue, additionalDetails, onClick, className, macroGroup, }) => {
    const { selectedGame, connectGameRegion, connectGameService, connectPlatform, dispatch, isLoading } = useAddGameContext();
    const handleConnect = async () => {
        var _a;
        const regionId = isRegion(connectGameRegion) ? connectGameRegion.id : undefined;
        const ugrData = {
            ...additionalDetails,
            lookupValue: lookupValue === null || lookupValue === void 0 ? void 0 : lookupValue.trim(),
            regionId,
            platformTag: connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.abbreviation,
            gameServiceKey: connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey,
            macroGroup,
        };
        dispatch(setIsLoadingAction(true));
        if (selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey) {
            try {
                const response = await postSaveGameDetails(ugrData, selectedGame.gameKey);
                dispatch(setGameConnectStatusAction('success'));
                dispatch(setFetchedSuccessResponse(response.data));
            }
            catch (error) {
                if (!(error instanceof SaveGameClientError)) {
                    captureException(error, {
                        extra: {
                            request: 'save_game_details',
                            gameKey: selectedGame.gameKey,
                            gameServiceKey: connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey,
                            regionId,
                            platformTag: connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.abbreviation,
                            macroGroup,
                        },
                    });
                }
                const errorResponse = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data;
                dispatch(setGameConnectStatusAction('error'));
                if (errorResponse) {
                    dispatch(setFetchedErrorResponse(errorResponse));
                }
            }
        }
        if (onClick && lookupValue) {
            onClick(lookupValue);
        }
    };
    return (_jsx(CoreButton, { className: className, type: "button", onClick: handleConnect, size: "lg", variant: getGamePalette((selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.gameKey) || 'primary'), disabled: !lookupValue, loading: isLoading, children: "Connect" }));
};
export default SaveGameDetails;
