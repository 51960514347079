import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';
import { makeStyles } from 'tss-react/mui';
import TextField from './TextField';
import BoxButton from '@components/Buttons/BoxButton';
const useStyles = makeStyles()(() => ({
    fieldWrapper: {
        height: '50px',
        background: '#F9F9F9',
        border: '1px solid #EBEBEB',
        borderRadius: '4px',
        color: '#9D9D9D',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 5px 5px 20px',
    },
    inputField: {
        '& input': {
            outline: 'none',
            background: 'transparent',
            border: '0 !important',
            flex: '1',
        },
        '& label': {
            textIndent: '-99999px',
            height: 0,
            position: 'absolute',
        },
    },
    submitBtn: {
        width: '100px',
        fontWeight: 'bold',
        height: '40px!important',
    },
    helperText: {
        textAlign: 'right',
        fontWeight: 600,
        marginTop: ' 9px',
        '& i': {
            marginLeft: '6px',
            fontWeight: 600,
        },
    },
    error: {
        color: '#e72020',
    },
}));
export const CombinedTextSubmit = ({ type = 'text', name, onChange, onBlur, value, label, hasError = false, helperText, className = '', placeholder = label, submitText, onSubmit, loading = false, autoComplete, autoFocus, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs("div", { className: cx('combinedTextSubmit', className), "data-testid": "combinedTextSubmit", children: [_jsxs("div", { className: cx(classes.fieldWrapper, hasError && 'error'), children: [_jsx(TextField, { type: type, name: name, onChange: (e) => onChange(e), onBlur: (e) => onBlur(e), value: value, className: cx(classes.inputField), "aria-label": label, placeholder: placeholder, disable: loading, label: label, autoComplete: autoComplete, autoFocus: true }), _jsx(BoxButton, { type: "submit", onClick: onSubmit, className: classes.submitBtn, disabled: loading, children: submitText })] }), helperText && (_jsx("div", { className: cx(classes.helperText, hasError && classes.error), "data-testid": "CombinedTextSubmitHelperText", children: _jsxs("p", { children: [helperText, hasError && _jsx(ExclamationTriangleIcon, { viewBox: "0 0 24 24" })] }) }))] }));
};
export default CombinedTextSubmit;
