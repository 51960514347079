import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import useAxiosFirebaseInterceptor from '@domain/App/Hooks/useAxiosFirebaseInterceptor';
import useClearReactQueryCacheOnLogout from '@domain/App/Hooks/useClearReactQueryCacheOnLogout';
import CustomAuthenticationModal from '@components/Modals/QueryActionModals/CustomAuthenticationModal';
/**
 * This component is used to stop the rendering of the child components,
 * if firebase auth is enabled, but firebase has not finished checking whether the user is logged in or not
 * (by checking the firebase indexDB)
 *
 * @param {React.ReactNode} param0 The react node
 * @param {React.ReactNode} param0.children The child nodes
 * @returns The children
 */
const FirebaseAuthGuard = ({ children }) => {
    const { isReady } = useAxiosFirebaseInterceptor();
    useClearReactQueryCacheOnLogout();
    if (!isReady) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(CustomAuthenticationModal, { children: _jsx(_Fragment, { children: children }) }));
};
export default FirebaseAuthGuard;
